var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form" }, [
    _c("h2", { staticClass: "form__heading" }, [
      _vm._v("Welcome to FindSourcing!"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return (function () {})($event)
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.errors.unknownError
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message form__message--error" }, [
                _c("span", {
                  staticClass: "form__message-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.error) },
                }),
                _vm._v("\n        Unknown error, please try again!\n      "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--full-width" },
          [
            _c(
              "fs-button-main",
              {
                attrs: { loading: _vm.loadingBuyer },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.buyer()
                  },
                },
              },
              [_vm._v("Buyer")]
            ),
            _vm._v(" "),
            _c(
              "fs-button-main",
              {
                attrs: { loading: _vm.loadingSupplier },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.supplier()
                  },
                },
              },
              [_vm._v("Supplier")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form__group form__group--message form__group--center" },
      [
        _c("p", { staticClass: "form__subheading" }, [
          _vm._v(
            "\n        To provide you with the best experience, please let us know a little bit more about you.\n      "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }