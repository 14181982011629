<template>
  <section class="form">
    <h2 class="form__heading">Welcome to FindSourcing!</h2>
    <form action="#" @submit.prevent="() => {}">
      <div class="form__group form__group--message form__group--center">
        <p class="form__subheading">
          To provide you with the best experience, please let us know a little bit more about you.
        </p>
      </div>
      <div class="form__group form__group--message" v-if="errors.unknownError">
        <p class="form__message form__message--error">
          <span class="form__message-icon" v-html="svg.error"></span>
          Unknown error, please try again!
        </p>
      </div>
      <div class="form__button-group form__button-group--full-width">
        <fs-button-main :loading="loadingBuyer" @click.prevent="buyer()">Buyer</fs-button-main>
        <fs-button-main :loading="loadingSupplier" @click.prevent="supplier()">Supplier</fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import error from '../../img/baseline-error-24px.svg';

export default {
  components: { FsButtonMain },
  data() {
    return {
      loadingBuyer: false,
      loadingSupplier: false,
      svg: {
        error,
      },
      errors: {
        unknownError: false,
      },
    };
  },
  methods: {
    async buyer() {
      this.loadingBuyer = true;
      this.errors.unknownError = false;

      try {
        await this.$store.buyers.add();
      } catch (e) {
        this.errors.unknownError = true;
      }

      this.loadingBuyer = false;
    },
    async supplier() {
      this.loadingSupplier = true;
      this.errors.unknownError = false;

      try {
        await this.$store.suppliers.addSupplier();
      } catch (e) {
        this.errors.unknownError = true;
      }

      this.loadingSupplier = false;
    },
  },
};
</script>
